<template>
  <div id="orderDetails">
    <van-nav-bar title="订单详情" left-arrow :border="false" @click-left="goSubPage('home')">
    </van-nav-bar>
    <ProductInfor @buttonClick="goExpectPrice" buttonText="去报价" @specilInfo="getSpecilInfo" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      isVerified: 0,
      orderImgList: [],
    };
  },
  components: {
    ProductInfor,
  },
  methods: {
    getSpecilInfo(e) {
      console.log(e);
      this.isVerified = e.worker_certification;
    },
    goExpectPrice() {
      switch (this.isVerified) {
        case 0:
          Dialog.alert({
            title: "实名认证",
            message: "您还没有实名认证！\n去实名认证吧",
            theme: "round-button",
            confirmButtonText: "去认证",
          }).then(() => {
            this.goSubPage("certification");
          });
          break;
        case 1:
          this.goSubPage("quoting", this.$route.params.orderId);
          break;
        case 2:
          Dialog.alert({
            title: "审核中",
            message: "实名认证审核中，请耐心等待哦",
            theme: "round-button",
            confirmButtonText: "好的",
          }).then(() => {
            this.goSubPage("home");
          });
          break;
        case -1:
          Dialog.alert({
            title: "审核不通过",
            message: "很抱歉，您的实名认证不通过，请您按照错误提示再次提交",
            theme: "round-button",
            confirmButtonText: "好的",
          }).then(() => {
            this.goNextPage("/verified");
          });
          break;
      }
    },
  },
};
</script>
<style lang="less">
</style>